// FIXME: would be nice to configure this from env
import authorizationUtil, { getAuthenticationToken } from '@/utils/authorization-util';
import { notification } from 'antd';
import { history } from 'umi';

export function getEnv() {
  const { hostname } = window.location;
  if (hostname.includes('staging')) return 'staging';
  else if (hostname.includes('demo')) return 'demo';
  else if (hostname.includes('dev')) return 'dev';
  else if (
    hostname.includes('oss-minds.pages.dev') ||
    hostname.includes('app.') ||
    hostname.includes('minds.oss.ventures')
  )
    return 'production';
  else return 'local';
}

export function getApiURL(): string {
  // FIXME: make this configurable
  const env = getEnv();
  switch (env) {
    case 'production':
      return 'https://api.yoshu.ai';
    case 'staging':
      return 'https://api-staging.yoshu.ai';
    case 'dev':
      return 'http://localhost:9380';
    case 'local':
      return 'http://localhost:9380';
    default:
      return '';
  }
}

let api_prefix = `${getApiURL()}/v1`;
export { api_prefix };

const config_api = {
  env: getEnv(),
  api_url: getApiURL(),
  api_prefix: api_prefix,
};

console.log('config_api', config_api);

/**
 * Returns a new {Request} with the 'Authorization' header set to the user token.
 * The request has its 'Content-Type' and 'Accept' headers set to 'application/json'
 * @param path {string} URI without leading slash, i.e "v1/agent"
 * @param method {string} HTTP method to use
 * @param body
 */
export async function authenticatedRequest<T>(path: string, method: string, body?: any): Promise<T> {
  const response = await fetch(`${getApiURL()}/${path}`, {
    headers: new Headers({
      Authorization: getAuthenticationToken(),
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
    method: method,
    body: typeof body === 'string' ? body : JSON.stringify(body),
  });

  if (!response.ok) {
    throw new Error(`${response.statusText}: ${await response.text()}`);
  }

  // FIXME: this stupid API completely ignores HTTP status codes, which means errors can return 200.
  // When we completely migrated the API, we can remove this
  const respBody = await response.json();

  // If true, this is a legacy API route
  if (respBody.retcode) {
    if (respBody.retcode === 401 || respBody.retcode === 401) {
      notification.error({
        message: respBody.retmsg,
        description: respBody.retmsg,
        duration: 3,
      });
      authorizationUtil.removeAll();
      history.push('/login'); // Will not jump to the login page
    }
    if (respBody.retcode >= 300 || respBody.retcode < 200) {
      throw new Error(`${response.statusText}: ${await response.text()}`);
    }
    return respBody.data as T;
  }

  return respBody as T;
}

export default {
  // user
  login: `${api_prefix}/user/login`,
  logout: `${api_prefix}/user/logout`,
  register: `${api_prefix}/user/register`,
  setting: `${api_prefix}/user/setting`,
  user_info: `${api_prefix}/user/info`,
  tenant_info: `${api_prefix}/user/tenant_info`,
  set_tenant_info: `${api_prefix}/user/set_tenant_info`,

  // llm model
  factories_list: `${api_prefix}/llm/factories`,
  llm_list: `${api_prefix}/llm/list`,
  my_llm: `${api_prefix}/llm/my_llms`,
  set_api_key: `${api_prefix}/llm/set_api_key`,
  add_llm: `${api_prefix}/llm/add_llm`,
  delete_llm: `${api_prefix}/llm/delete_llm`,

  // knowledge base
  kb_list: `${api_prefix}/kb/list`,
  create_kb: `${api_prefix}/kb/create`,
  update_kb: `${api_prefix}/kb/update`,
  rm_kb: `${api_prefix}/kb/rm`,
  get_kb_detail: `${api_prefix}/kb/detail`,

  // chunk
  chunk_list: `${api_prefix}/chunk/list`,
  create_chunk: `${api_prefix}/chunk/create`,
  set_chunk: `${api_prefix}/chunk/set`,
  get_chunk: `${api_prefix}/chunk/get`,
  switch_chunk: `${api_prefix}/chunk/switch`,
  rm_chunk: `${api_prefix}/chunk/rm`,
  retrieval_test: `${api_prefix}/chunk/retrieval_test`,
  knowledge_graph: `${api_prefix}/chunk/knowledge_graph`,

  // document
  get_document_list: `${api_prefix}/document/list`,
  document_change_status: `${api_prefix}/document/change_status`,
  document_rm: `${api_prefix}/document/rm`,
  document_delete: `${api_prefix}/api/document`,
  document_rename: `${api_prefix}/document/rename`,
  document_create: `${api_prefix}/document/create`,
  document_run: `${api_prefix}/document/run`,
  document_change_parser: `${api_prefix}/document/change_parser`,
  document_thumbnails: `${api_prefix}/document/thumbnails`,
  get_document_file: `${api_prefix}/document/get`,
  document_upload: `${api_prefix}/document/upload`,
  web_crawl: `${api_prefix}/document/web_crawl`,
  document_infos: `${api_prefix}/document/infos`,

  // chat
  setDialog: `${api_prefix}/dialog/set`,
  getDialog: `${api_prefix}/dialog/get`,
  removeDialog: `${api_prefix}/dialog/rm`,
  listDialog: `${api_prefix}/dialog/list`,
  setConversation: `${api_prefix}/conversation/set`,
  getConversation: `${api_prefix}/conversation/get`,
  listConversation: `${api_prefix}/conversation/list`,
  removeConversation: `${api_prefix}/conversation/rm`,
  completeConversation: `${api_prefix}/conversation/completion`,
  // chat for external
  createToken: `${api_prefix}/api/new_token`,
  listToken: `${api_prefix}/api/token_list`,
  removeToken: `${api_prefix}/api/rm`,
  getStats: `${api_prefix}/api/stats`,
  createExternalConversation: `${api_prefix}/api/new_conversation`,
  getExternalConversation: `${api_prefix}/api/conversation`,
  completeExternalConversation: `${api_prefix}/api/completion`,

  // file manager
  listFile: `${api_prefix}/file/list`,
  uploadFile: `${api_prefix}/file/upload`,
  removeFile: `${api_prefix}/file/rm`,
  renameFile: `${api_prefix}/file/rename`,
  getAllParentFolder: `${api_prefix}/file/all_parent_folder`,
  createFolder: `${api_prefix}/file/create`,
  connectFileToKnowledge: `${api_prefix}/file2document/convert`,
  getFile: `${api_prefix}/file/get`,
  moveFile: `${api_prefix}/file/mv`,

  // system
  getSystemVersion: `${api_prefix}/system/version`,
  getSystemStatus: `${api_prefix}/system/status`,

  // flow
  listTemplates: `${api_prefix}/canvas/templates`,
  listCanvas: `${api_prefix}/canvas/list`,
  getCanvas: `${api_prefix}/canvas/get`,
  removeCanvas: `${api_prefix}/canvas/rm`,
  setCanvas: `${api_prefix}/canvas/set`,
  resetCanvas: `${api_prefix}/canvas/reset`,
  runCanvas: `${api_prefix}/canvas/completion`,
  testDbConnect: `${api_prefix}/canvas/test_db_connect`,
};
